/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 26px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #04a5f4;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 28px;
  width: 28px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
	z-index:500;
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
display: block;
  background: #1b242d;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
   overflow:hidden;
   z-index:500;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  max-height:85%;
     overflow:hidden;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: #d1d1d1;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.bm-item:hover {
  color: #ffffff;
}
body {
scrollbar-width: thin;
}


@media only screen and (max-width: 800px) {
img {
width: '290px';
height: 'auto';
}
}
/* @media screen and (max-width: 55rem) {
  .bm-burger-button {
    display: none;
  }
} */



/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/









 
